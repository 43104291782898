import React, { Component } from "react";
import PropTypes from "prop-types";
import { SignedIn, SignedOut } from "../UserState/UserState";
import EpisodeServers from "../Servers/EpisodeServers";
import "./EpisodeMobileItem.scss";
import Seo from "../Seo";
import ShareButton from "../PrimaryButton/ShareButton";

// Função para gerar a URL de download
function generateDownloadUrl(isMovie, id, seasonNumber, episodeNumber) {
  if (isMovie) {
    return `https://dl.vidsrc.vip/movie/${id}`;
  } else {
    return `https://dl.vidsrc.vip/tv/${id}/${seasonNumber}/${episodeNumber}`;
  }
}

class EpisodeMobileItem extends Component {
  static propTypes = {
    addEpisode: PropTypes.func.isRequired,
    removeEpisode: PropTypes.func.isRequired,
    watched: PropTypes.bool.isRequired,
    showId: PropTypes.string.isRequired,
    episodeNumber: PropTypes.number.isRequired,
    seasonNumber: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    isMovie: PropTypes.bool,  // Adicionado para verificar se é filme
  };

  state = {
    isOpen: false,
    modalIsOpen: false,
  };

  showModal = () => {
    this.setState({ modalIsOpen: true });
  };

  hideModal = () => {
    this.setState({ modalIsOpen: false});
  };

  toggle = () => {
    this.setState((state) => ({ isOpen: !state.isOpen }));
  };

  render() {
    const { toggle, hideModal, showModal } = this;
    const { modalIsOpen } = this.state;
    const {
      addEpisode,
      removeEpisode,
      watched,
      name,
      title,
      episodeNumber,
      seasonNumber,
      description,
      poster,
      showId,
      isMovie,
    } = this.props;

    let mobileItem;
    let checkBox;
    const show = {
      id: showId,
      episodeNumber,
      seasonNumber,
    };

    if (watched) {
      checkBox = (
        <button
          className="episodeMobileCheckbox"
          onClick={() => removeEpisode(show)}
        />
      );
    } else {
      checkBox = (
        <button
          className="episodeMobileCheckbox"
          onClick={() => addEpisode(show)}
        />
      );
    }

    if (this.state.isOpen) {
      mobileItem = (
        <div className="expandedItemBox">
          <div className="expandedTitleBar">
            <div className="episodeNumber">{episodeNumber}</div>
            <div className="episodeTitleBox" onClick={toggle}>{name}</div>
          </div>
          <div className="expandedDescriptionBox">
            {description} <br />
            <ShareButton onClick={showModal} title={`Play Episode ${episodeNumber}`} style={{ 'display': name ? 'block' : 'none', 'margin-top': '10px'}} />
            {!isMovie && (
              <a 
                href={generateDownloadUrl(isMovie, showId, seasonNumber, episodeNumber)} 
                target="_blank" 
                rel="noopener noreferrer"
                className="share-button primary-button"
                style={{ 'display': 'block', 'margin-top': '10px'}}
              >
                Download
              </a>
            )}
          </div>
          <Seo
            title={`You're Watching (${name}): Episode ${episodeNumber}, Season ${seasonNumber}`}
            descriptions={`Watch (${name}): Episode ${episodeNumber}, Season ${seasonNumber} in HD Quality for free!`}
            keywords={`Watch and Download ${name} in 480p, 720p, 1080p HD Quality for free!`}
          />
          <EpisodeServers
            isOpen={modalIsOpen}
            hideFunc={hideModal}
            showId={showId}
            url={poster}
            episodeNumber={episodeNumber}
            seasonNumber={seasonNumber}
            name={name}
            title={title}
          />
        </div>
      );
    } else {
      mobileItem = (
        <div className="episodeMobileItem">
          <div>
            <div className="episodeNumber">{episodeNumber}</div>
          </div>
          <div className="episodeMobileTitle">{name}</div>
          <button className="expandBoxButton" onClick={toggle} />
          <SignedIn>{() => checkBox}</SignedIn>
          <SignedOut>
            <div />
          </SignedOut>
        </div>
      );
    }

    return (
      <>
        {mobileItem}
      </>
    );
  }
}

export default EpisodeMobileItem;
