import React, { Component } from "react";

class EpisodeServers extends Component {
  state = {
    iframeSrc: "", // Estado para gerenciar o src do iframe
  };

  iframeRef = React.createRef(); // ReferÃªncia para o iframe

  componentDidUpdate(prevProps) {
    if (!prevProps.isOpen && this.props.isOpen) {
      // Configura o src do iframe ao abrir o modal
      const { showId, seasonNumber, episodeNumber } = this.props;
      this.setState({
        iframeSrc: `https://autoembed.pro/embed/tv/${showId}/${seasonNumber}/${episodeNumber}?player=1`,
      });
    }
  }

  cancelModal = () => {
    // Limpa o src do iframe para parar o conteÃºdo ao fechar o modal
    this.setState({ iframeSrc: "" });
    this.props.hideFunc();
  };

  render() {
    const { isOpen } = this.props;
    const isVideoOpen = isOpen ? "is-modal-active" : "";

    return (
      <div className={`modal modal--fullscreen ${isVideoOpen}`}>
        <div className="modal__dialog">
          <div className="modal__content" style={{ position: "relative" }}>
            <button
              onClick={this.cancelModal}
              style={{
                position: "absolute",
                top: "10px",
                left: "10px", // Move o botÃ£o para o lado esquerdo
                zIndex: 1000,
                background: "red",
                color: "white",
                border: "none",
                padding: "10px",
                borderRadius: "5px",
                cursor: "pointer",
              }}
            >
              Close
            </button>
            <iframe
              ref={this.iframeRef}
              src={this.state.iframeSrc} // Usa o estado para controlar o src
              style={{
                width: "100%",
                height: "100%",
                border: "none",
              }}
              allowFullScreen // Permite que o usuÃ¡rio ative o fullscreen manualmente
              title="Episode Player"
            />
          </div>
        </div>
      </div>
    );
  }
}

export default EpisodeServers;
