import React, { Component } from "react";
import PropTypes from "prop-types";
import { 
  getNowScifiMovies, 
  getNowKidsMovies, 
  getNowThrillerMovies, 
  getNowHorrorMovies, 
  getNowPlayingMovies, 
  getNowAiringTVShows,
  getNowDocumentaryShows, 
  getNowNetFlix,
  getNowNetHbo,
  getNowPrimeVideo,
  getNowDisneyPlus,
  getNowAppleTV,
  getNowStarPlus,
  getNowParamountPlus,
  getNowGloboplay
} from "../api/tmdb";
import Homepage from "../components/Homepage/Homepage";
import ErrorMessage from "../components/ErrorMessage/ErrorMessage";
import MovieCarousel from "../components/Carousel/MovieCarousel"; // Importando o carrossel

class HomepageContainer extends Component {
  static propTypes = {
    horror: PropTypes.array.isRequired,
    movies: PropTypes.array.isRequired,
    series: PropTypes.array.isRequired,
    thriller: PropTypes.array.isRequired,
    kids: PropTypes.array.isRequired,
    scifi: PropTypes.array.isRequired,
    documentary: PropTypes.array.isRequired,
    netflix: PropTypes.array.isRequired,
    hbo: PropTypes.array.isRequired,
    prime: PropTypes.array.isRequired,
    disney: PropTypes.array.isRequired,
    apple: PropTypes.array.isRequired,
    star: PropTypes.array.isRequired,
    paramount: PropTypes.array.isRequired,
    globoplay: PropTypes.array.isRequired,
    setNowScifiMovies: PropTypes.func.isRequired,
    setNowKidsMovies: PropTypes.func.isRequired,
    setNowThrillerMovies: PropTypes.func.isRequired,
    setNowHorrorMovies: PropTypes.func.isRequired,
    setNowPlayingMovies: PropTypes.func.isRequired,
    setNowAiringTVShows: PropTypes.func.isRequired,
    setNowDocumentaries: PropTypes.func.isRequired,
    setNowNetflix: PropTypes.func.isRequired,
    setNowHbo: PropTypes.func.isRequired,
    setNowPrime: PropTypes.func.isRequired,
    setNowDisney: PropTypes.func.isRequired,
    setNowApple: PropTypes.func.isRequired,
    setNowStar: PropTypes.func.isRequired,
    setNowParamount: PropTypes.func.isRequired,
    setNowGloboplay: PropTypes.func.isRequired,
  };

  static getDerivedStateFromProps(props) {
    if (
      props.documentary.length !== 0 && 
      props.kids.length !== 0 && 
      props.scifi.length !== 0 && 
      props.horror.length !== 0 && 
      props.thriller.length !== 0 && 
      props.movies.length !== 0 && 
      props.series.length !== 0 && 
      props.netflix.length !== 0 && 
      props.hbo.length !== 0 &&
      props.prime.length !== 0 &&
      props.disney.length !== 0 &&
      props.apple.length !== 0 &&
      props.star.length !== 0 &&
      props.paramount.length !== 0 &&
      props.globoplay.length !== 0
    ) {
      return {
        loadingKids: false,
        loadingScifi: false,
        loadingMovies: false,
        loadingThriller: false,
        loadingShows: false,
        loadingHorror: false,
        loadingDocumentaries: false,
        loadingNetflix: false,
        loadingHbo: false,
        loadingPrime: false,
        loadingDisney: false,
        loadingApple: false,
        loadingStar: false,
        loadingParamount: false,
        loadingGloboplay: false,
      };
    }
    return null;
  }

  state = {
    error: false,
    loadingScifi: true,
    loadingKids: true,
    loadingHorror: true,
    loadingMovies: true,
    loadingShows: true,
    loadingThriller: true,
    loadingDocumentaries: true,
    loadingNetflix: true,
    loadingHbo: true,
    loadingPrime: true,
    loadingDisney: true,
    loadingApple: true,
    loadingStar: true,
    loadingParamount: true,
    loadingGloboplay: true,
  };

  componentDidMount() {
    const {documentary: dc, scifi: sc, kids: k, thriller: t, horror: h, movies: m, series: s, netflix: n, hbo: bb, prime: p, disney: d, apple: a, star: st, paramount: pa, globoplay: g} = this.props;
    if (
      dc.length !== 0 && 
      k.length !== 0 && 
      sc.length !== 0 && 
      h.length !== 0 && 
      t.length !== 0 && 
      m.length !== 0 && 
      s.length !== 0 && 
      n.length !== 0 && 
      bb.length !== 0 &&
      p.length !== 0 &&
      d.length !== 0 &&
      a.length !== 0 &&
      st.length !== 0 &&
      pa.length !== 0 &&
      g.length !== 0
    ) return;

    getNowDocumentaryShows()
      .then(documentary => {
        if (!documentary) return;
        this.props.setNowDocumentaries(documentary.splice(0, 20));
        this.setState({ loadingDocumentaries: false });
      }).catch(() => { this.setState({ error: true, loadingDocumentaries: false }); });

    getNowScifiMovies()
      .then(scifi => {
        if (!scifi) return;
        this.props.setNowScifiMovies(scifi.splice(0, 20));
        this.setState({ loadingScifi: false });
      }).catch(() => { this.setState({ error: true, loadingScifi: false }); });

    getNowKidsMovies()
      .then(kids => {
        if (!kids) return;
        this.props.setNowKidsMovies(kids.splice(0, 20));
        this.setState({ loadingKids: false });
      }).catch(() => { this.setState({ error: true, loadingKids: false }); });

    getNowThrillerMovies()
      .then(thriller => {
        if (!thriller) return;
        this.props.setNowThrillerMovies(thriller.splice(0, 20));
        this.setState({ loadingThriller: false });
      }).catch(() => { this.setState({ error: true, loadingThriller: false }); });

    getNowHorrorMovies()
      .then(horror => {
        if (!horror) return;
        this.props.setNowHorrorMovies(horror.splice(0, 20));
        this.setState({ loadingHorror: false });
      }).catch(() => { this.setState({ error: true, loadingHorror: false }); });

    getNowPlayingMovies()
      .then(movies => {
        if (!movies) return;
        this.props.setNowPlayingMovies(movies.splice(0, 20));
        this.setState({ loadingMovies: false });
      }).catch(() => { this.setState({ error: true, loadingMovies: false }); });

    getNowAiringTVShows()
      .then(series => {
        if (!series) return;
        this.props.setNowAiringTVShows(series.splice(0, 20));
        this.setState({ loadingShows: false });
      }).catch(() => { this.setState({ error: true, loadingShows: false }); });

    getNowNetFlix()
      .then(netflix => {
        if (!netflix) return;
        this.props.setNowNetflix(netflix.splice(0, 20));
        this.setState({ loadingNetflix: false });
      }).catch(() => { this.setState({ error: true, loadingNetflix: false }); });
    
    getNowNetHbo()
      .then(hbo => {
        if (!hbo) return;
        this.props.setNowHbo(hbo.splice(0, 20));
        this.setState({ loadingHbo: false });
      }).catch(() => { this.setState({ error: true, loadingHbo: false }); });

    getNowPrimeVideo()
      .then(prime => {
        if (!prime) return;
        this.props.setNowPrime(prime.splice(0, 20));
        this.setState({ loadingPrime: false });
      }).catch(() => { this.setState({ error: true, loadingPrime: false }); });

    getNowDisneyPlus()
      .then(disney => {
        if (!disney) return;
        this.props.setNowDisney(disney.splice(0, 20));
        this.setState({ loadingDisney: false });
      }).catch(() => { this.setState({ error: true, loadingDisney: false }); });

    getNowAppleTV()
      .then(apple => {
        if (!apple) return;
        this.props.setNowApple(apple.splice(0, 20));
        this.setState({ loadingApple: false });
      }).catch(() => { this.setState({ error: true, loadingApple: false }); });

    getNowStarPlus()
      .then(star => {
        if (!star) return;
        this.props.setNowStar(star.splice(0, 20));
        this.setState({ loadingStar: false });
      }).catch(() => { this.setState({ error: true, loadingStar: false }); });

    getNowParamountPlus()
      .then(paramount => {
        if (!paramount) return;
        this.props.setNowParamount(paramount.splice(0, 20));
        this.setState({ loadingParamount: false });
      }).catch(() => { this.setState({ error: true, loadingParamount: false }); });

    getNowGloboplay()
      .then(globoplay => {
        if (!globoplay) return;
        this.props.setNowGloboplay(globoplay.splice(0, 20));
        this.setState({ loadingGloboplay: false });
      }).catch(() => { this.setState({ error: true, loadingGloboplay: false }); });
  }

  render() {
    if (this.state.error) {
      return (
        <div className="container">
          <ErrorMessage>O-o-oh! Something broke.</ErrorMessage>
        </div>
      );
    }

    return (
      <>
        <MovieCarousel /> {/* Adicionando o carrossel aqui */}
        <Homepage
          movies={this.props.movies}
          series={this.props.series}
          horror={this.props.horror}
          thriller={this.props.thriller}
          kids={this.props.kids}
          scifi={this.props.scifi}
          documentary={this.props.documentary}
          netflix={this.props.netflix}
          hbo={this.props.hbo}
          prime={this.props.prime}
          disney={this.props.disney}
          apple={this.props.apple}
          star={this.props.star}
          paramount={this.props.paramount}
          globoplay={this.props.globoplay}
          loadingScifi={this.state.loadingScifi}
          loadingKids={this.state.loadingKids}
          loadingThriller={this.state.loadingThriller}
          loadingMovies={this.state.loadingMovies}
          loadingShows={this.state.loadingShows}
          loadingHorror={this.state.loadingHorror}
          loadingDocumentaries={this.state.loadingDocumentaries}
          loadingNetflix={this.state.loadingNetflix}
          loadingHbo={this.state.loadingHbo}
          loadingPrime={this.state.loadingPrime}
          loadingDisney={this.state.loadingDisney}
          loadingApple={this.state.loadingApple}
          loadingStar={this.state.loadingStar}
          loadingParamount={this.state.loadingParamount}
          loadingGloboplay={this.state.loadingGloboplay}
        />
      </>
    );
  }
}

export default HomepageContainer;
