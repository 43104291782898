import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import { getNowPlayingMovies, getFullImgPath } from '../lib/tmdbHelper';
import './MovieCarousel.css';

const MovieCarousel = () => {
  const [movies, setMovies] = useState([]);

  useEffect(() => {
    async function fetchMovies() {
      const moviesData = await getNowPlayingMovies();
      setMovies(moviesData);
    }
    fetchMovies();
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: '0',
    autoplay: true,          // Adiciona rolagem automática
    autoplaySpeed: 3000,     // Define a velocidade da rolagem automática (3 segundos)
  };

  return (
    <div className="carousel">
      <Slider {...settings}>
        {movies.map((movie) => (
          <div key={movie.id} className="slide">
            <Link to={`/movie/${movie.id}`}>
              <img src={getFullImgPath(movie.poster_path, 'w500')} alt={movie.title} />
              <h3>{movie.title}</h3>
            </Link>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default MovieCarousel;
