// lib/tmdbHelper.js
const API_KEY = "45182786638fe524a433bd5af884766c"
const baseImgUrl = "https://image.tmdb.org/t/p/";
const baseUrl = "https://api.themoviedb.org/3";

const API_ERROR_CODE = 7;
const RESOURCE_NOT_FOUND = 34;

const language = typeof window !== 'undefined' && localStorage.getItem('language') ? '&language=' + localStorage.getItem('language') : "";

/**
 * Takes the poster_path or backdrop_path value from the API and an optional
 * size parameter and returns the full image URL.
 */
export const getFullImgPath = (imgPath, size = "original") =>
  `${baseImgUrl}${size}${imgPath}`;

/**
 * Takes a response from a fetch, verifies it, and returns the JSON
 */
async function checkResponse(res) {
  const json = await res.json();
  if (json.status_code === API_ERROR_CODE) throw new Error("Invalid API key");
  if (json.status_code === RESOURCE_NOT_FOUND)
    throw new Error("Couldn't find anything =(");

  return json;
}

export function getNowPlayingMovies() {
  const nowPlayingMovieUrl = `${baseUrl}/movie/now_playing?api_key=${API_KEY}&page=1${language}`;
  return fetch(nowPlayingMovieUrl)
    .then(checkResponse)
    .then(json => json.results);
}
