import React, { useEffect } from "react";
import PropTypes from "prop-types";
import DetailsBanner from "../DetailsBanner/DetailsBanner";
import DetailsTitle from "../DetailsTitle/DetailsTitle";
import MovieInformation from "../MovieInformation/MovieInformation";
import LoadingDetailsPage from "../Loading/LoadingDetailsPage";

/**
 * Markup for the details page
 */

function DetailsPage({ currentMovie, isLoading }) {
  useEffect(() => {
    if (typeof window !== "undefined") {
      const _Hasync = window._Hasync || [];
      _Hasync.push(['Histats.start', '1,4909860,4,0,0,0,00010000']);
      _Hasync.push(['Histats.fasi', '1']);
      _Hasync.push(['Histats.track_hits', '']);

      const hs = document.createElement("script");
      hs.type = "text/javascript";
      hs.async = true;
      hs.src = "//s10.histats.com/js15_as.js";
      document.head.appendChild(hs);
    }
  }, []); // Runs once after component mounts

  if (isLoading) return <LoadingDetailsPage />;

  return (
    <div id="detailspage">
      <DetailsBanner backdropPath={currentMovie.backdrop_path} />
      <DetailsTitle movie={currentMovie} />
      <MovieInformation currentMovie={currentMovie} />
    </div>
  );
}

DetailsPage.propTypes = {
  currentMovie: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default DetailsPage;
