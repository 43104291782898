import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";

// Initialize Firebase
const config = {
    apiKey: "AIzaSyAZLQOwlaW38aqHC2lcikUUsPqr14HPCMc",
    authDomain: "cave-14140.firebaseapp.com",
    projectId: "cave-14140",
    storageBucket: "cave-14140.appspot.com",
    messagingSenderId: "856240250338",
    appId: "1:856240250338:web:58b92cc29b226f0ecaadbb",
    measurementId: "G-6Z0H2SHV16"
};

const firebaseApp = firebase.initializeApp(config);

// Initialize the Firestore database
const db = firebase.firestore();
const auth = firebase.auth();

export { db, auth };
export default firebaseApp;